import React, { useMemo } from "react";
import { StyledChampionsLeaderboardHeader } from "./style";
import SeasonInfo from "../../../Seasons/Info";
import { useTranslation } from "react-i18next";
import ChampionsSearch from "../../Search";
import Dropdown, { DropdownOptionGroup } from "../../../Ui/Dropdown";
import { Leaderboard } from "../../../../services/championsQueueData/index.type";

export type Props = {
  leaderboards: Leaderboard[]
  selected: Leaderboard
  onSelectLeaderboard: (leaderboard: Leaderboard) => void
  onSearch: (query: string) => void
};

const ChampionsLeaderboardHeader: React.FC<Props> = ({
  leaderboards,
  selected,
  onSelectLeaderboard,
  onSearch
}: Props) => {
  const { t } = useTranslation();

  const options: DropdownOptionGroup<Leaderboard>[] = useMemo(
    () => buildOptions(leaderboards),
    [leaderboards]
  );

  return (
    <StyledChampionsLeaderboardHeader>
      <SeasonInfo className="season-info" leaderboard={selected} />
      <div className="header-row">
        <h2>{t("leaderboard:leaderboard")}</h2>
        <div className="right-side">
          <ChampionsSearch onSearch={onSearch} />
          <Dropdown
            className="previous-seasons-dropdown"
            label={t("leaderboard:labels.seePreviousSeasons")}
            options={options}
            selected={selected}
            getKey={getKey}
            onChange={onSelectLeaderboard}
          />
        </div>
      </div>
    </StyledChampionsLeaderboardHeader>
  );
};

const getKey = (leaderboard: Leaderboard) =>
  `${leaderboard.title}${leaderboard.split?.title ?? ""}`;

const buildOptions = (leaderboards: Leaderboard[]): DropdownOptionGroup<Leaderboard>[] => {
  if (leaderboards.length === 0) {
    return [];
  }

  const bySeason: Record<string, Leaderboard[]> = {};

  leaderboards.sort((a,b) => Date.parse(b.openDate) - Date.parse(a.openDate));
  leaderboards.forEach((leaderboard) => {
    let seasonBoards = bySeason[leaderboard.title];
    if (!seasonBoards) {
      seasonBoards = [];
      bySeason[leaderboard.title] = seasonBoards;
    }
    seasonBoards.push(leaderboard);
    seasonBoards.sort((a,b) => Date.parse(a.openDate) - Date.parse(b.openDate));
  });

  const groups: DropdownOptionGroup<Leaderboard>[] = [];

  Object.values(bySeason).forEach((seasonLeaderboards) => {
    const seasonLeaderboard =
      seasonLeaderboards.find((leaderboard) => !leaderboard.split);
    const splitLeaderboards =
      seasonLeaderboards.filter((leaderboard) => leaderboard.split);

    if (seasonLeaderboard) {
      const group: DropdownOptionGroup<Leaderboard> = {
        value: seasonLeaderboard,
        label: seasonLeaderboard.title
      };

      if (splitLeaderboards.length > 0) {
        group.children = splitLeaderboards.map((leaderboard) => ({
          value: leaderboard,
          label: leaderboard.split!.title
        }));
      }

      groups.push(group);
    } else {
      splitLeaderboards.forEach((leaderboard) => {
        groups.push({
          value: leaderboard,
          label: leaderboard.split!.title
        });
      });
    }
  });

  return groups;
};

export default ChampionsLeaderboardHeader;
