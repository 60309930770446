import styled from "styled-components";
import { device } from "../../Layout/theme";

const columnWidthsLarge = {
  lp: "100px",
  seasonPoints: "180px",
  winRate: "180px",
};

const columnWidthsMedium = {
  lp: "90px",
  seasonPoints: "100px",
  winRate: "110px",
};

export const StyledChampionsLeaderboard = styled.div`
  @media ${device.large} {
    margin: 100px ${({ theme }) => theme.gutter_size_large};

    .lp {
      width: ${columnWidthsLarge.lp};
    }

    .seasonPoints {
      width: ${columnWidthsLarge.seasonPoints};
    }

    .winRate {
      width: ${columnWidthsLarge.winRate};
    }
  }

  @media ${device.medium} {
    margin: 100px ${({ theme }) => theme.gutter_size_medium};

    .lp {
      width: ${columnWidthsMedium.lp};
    }

    .seasonPoints {
      width: ${columnWidthsMedium.seasonPoints};
    }

    .winRate {
      width: ${columnWidthsMedium.winRate};
    }
  }

  @media ${device.small} {
    margin: 100px ${({ theme }) => theme.gutter_size_small};

    .lp,
    .seasonPoints {
      width: auto;
    },
    .winRate {
      width: auto;
    },
  }
`;
