import styled from "styled-components";
import { device, StyleFontHeader } from "../../../Layout/theme";

export const StyledChampionsLeaderboardHeader = styled.div`
  .season-info {
    justify-content: flex-start;
  }

  h2 {
    ${StyleFontHeader}
    font-size: 55px;
    letter-spacing: 0.05em;
  }

  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-side {
      display: flex;
      align-items: flex-end;

      .previous-seasons-dropdown {
        margin-left: 50px;
      }
    }
  }

  @media ${device.medium} {
    .header-row {
      .right-side {
        margin-left: 20px;

        .previous-seasons-dropdown {
          margin-left: 20px;
        }
      }
    }
  }

  @media ${device.small} {
    .header-row {
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      margin-bottom: 30px;

      .right-side {
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;

        .previous-seasons-dropdown {
          margin-left: 0;
          margin-top: 50px;
        }
      }
    }
  }
`;
