import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { StyledLeaderboardFeature } from "./style";

const LeaderboardFeature: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <StyledLeaderboardFeature>
      <div className="banner">
        <StaticImage
          className="image"
          src="../../../images/emea_banner.png"
          alt={t("title")}
          quality={100}
        />
      </div>
    </StyledLeaderboardFeature>
  );
};

export default LeaderboardFeature;
