import styled from "styled-components";
import { device, TextTransformUppercase } from "../../Layout/theme";

export const StyledSeasonInfo = styled.div`
  ${TextTransformUppercase}
  color: ${({ theme }) => theme.color_accent};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.small} {
    text-align: center;
  }
`;

export const StyledDivider = styled.span`
  width: 1px;
  height: 14px;
  background-color: ${({ theme }) => theme.color_accent};
  display: inline-block;
  margin: 0 10px;
`;
