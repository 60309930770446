import styled from "styled-components";
import { device } from "../../Layout/theme";

export const StyledLeaderboardFeature = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  @media ${device.large} {
    .image {
      margin: ${({ theme }) => theme.gutter_size_large};
    }

  @media ${device.medium} {
    .image {
      margin: ${({ theme }) => theme.gutter_size_medium};
    }
  }

  @media ${device.small} {
    .image {
      margin: ${({ theme }) => theme.gutter_size_small};
    }
  }
`;
