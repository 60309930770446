import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { Fragment } from "react";
import Layout from "../components/Layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LeaderboardFeature from "../components/Feature/Leaderboard";
import LeadingChampions from "../components/Champions/Leading";
import { fetchLeaderboard } from "../services/championsQueueData";
import { useFetch } from "../hooks/use-fetch";
import { Leaderboard } from "../services/championsQueueData/index.type";
import ChampionsLeaderboard from "../components/Champions/Leaderboard";
import { StyledLayoutContentArea } from "../components/Layout/style";
import InformLoading from "../components/Inform/Loading";
import InformError from "../components/Inform/Error";
import placeholder from "../placeholders/leaderboard.json";

const IndexPage: React.FC = () => {
  const { t } = useTranslation();

  const { isLoading, response, error } = useFetch(fetchLeaderboard, []);

  const leaderboards = response?.leaderboards || [placeholder];
  const currentLeaderboard = (response?.leaderboards && findCurrentLeaderboard(leaderboards)) || placeholder;
  const currentSeasonLeaderboard =
    (response?.leaderboards && findCurrentSeasonLeaderboard(leaderboards)) || placeholder;

  const renderLeaderboard = (splitLeaderboard: Leaderboard, seasonLeaderboard: Leaderboard, all: Leaderboard[]) => (
    <Fragment>
      <LeadingChampions current={seasonLeaderboard} />
      <ChampionsLeaderboard
        leaderboards={all}
        current={splitLeaderboard}
      />
    </Fragment>
  );

  return (
    <Layout>
      <GatsbySeo title={t("title")} />
      <LeaderboardFeature/>
      <StyledLayoutContentArea>
        {isLoading && <InformLoading />}
        {error && <InformError error={error} />}
        {!isLoading && (
          <Fragment>
            {renderLeaderboard(currentLeaderboard, currentSeasonLeaderboard, leaderboards)}
          </Fragment>
        )}
      </StyledLayoutContentArea>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    ...TranslationQueryFragment
  }
`;

const findCurrentLeaderboard = (leaderboards: Leaderboard[]): Leaderboard | undefined => {
  if (leaderboards.length === 0) {
    return undefined;
  }

  const now = Date.now();

  const startedOpenSplits = leaderboards.filter(
    ({ split }) => split && now >= Date.parse(split.openDate) && now < Date.parse(split.closeDate)
  );

  const latestStartedOpenSplit = startedOpenSplits.length > 0 && startedOpenSplits.reduce(
    (a, b) => Date.parse(a.split!.openDate) > Date.parse(b.split!.openDate) ? a : b
  );

  const startedSeasons = leaderboards.filter(
    ({ openDate, split }) => !split && now >= Date.parse(openDate)
  );

  const latestStartedSeason = startedSeasons.length > 0 && startedSeasons.reduce(
    (a, b) => Date.parse(a.openDate) > Date.parse(b.openDate) ? a : b
  );

  // Any leaderboard starting closest to now as a fallback.
  const closest = leaderboards.reduce((a, b) => {
    const lengthA = Math.abs(now - Date.parse(a.openDate));
    const lengthB = Math.abs(now - Date.parse(b.openDate));

    return lengthA < lengthB ? a : b;
  });

  // First, default to the current open/active split.
  // If none are open, default to the latest season that has started.
  // If none are started, default to the leaderboard that is closest in timeline (either forwards or backwards)
  return latestStartedOpenSplit || latestStartedSeason || closest;
};

const findCurrentSeasonLeaderboard = (leaderboards: Leaderboard[]): Leaderboard | undefined => {
  if (leaderboards.length === 0) {
    return undefined;
  }

  const now = Date.now();

  const startedSeasons = leaderboards.filter(
    ({ openDate, split }) => !split && now >= Date.parse(openDate)
  );

  const latestStartedSeason = startedSeasons.length > 0 && startedSeasons.reduce(
    (a, b) => Date.parse(a.openDate) > Date.parse(b.openDate) ? a : b
  );

  // Any season leaderboard starting closest to now as a fallback.
  const closest = startedSeasons.reduce((a, b) => {
    const lengthA = Math.abs(now - Date.parse(a.openDate));
    const lengthB = Math.abs(now - Date.parse(b.openDate));

    return lengthA < lengthB ? a : b;
  });

  // Default to the latest season that has started.
  // If none are started, default to the season leaderboard that is closest in timeline (either forwards or backwards)
  return latestStartedSeason || closest;
};
