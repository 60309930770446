import React, { useEffect, useMemo, useState } from "react";
import { StyledChampionsLeaderboard } from "./style";
import ChampionsLeaderboardHeader from "./Header";
import ChampionsLeaderboardTable, { StatColumn } from "./Table";
import {
  Leaderboard,
  LeaderboardPlayer
} from "../../../services/championsQueueData/index.type";
import Fuse from "fuse.js";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { createFuse } from "../../../helpers/utils";

export type Props = {
  leaderboards: Leaderboard[]
  current: Leaderboard
};

const ChampionsLeaderboard: React.FC<Props> = ({
  leaderboards,
  current
}: Props) => {
  const { i18n, t } = useTranslation();

  const [currentQuery, setCurrentQuery] = useState<string>("");

  const [selected, setSelected] = useState<Leaderboard>(current);

  const [playersFiltered, setPlayersFiltered] =
    useState<LeaderboardPlayer[]>(selected.lineup);

  const seasonLeaderboards = leaderboards.reduce(
    (count, lb) => {
      return lb.seasonId == selected.seasonId ? count + 1 : count
    }, 0);
  // Replace sp with lp for single-split seasonal lb
  const replaceSeasonPoints = selected.split === null && seasonLeaderboards == 1;

  const includeSeasonPoints =
    selected.lineup.some((player) => player.seasonPoints > 0);
  const includeWinRate = selected.split === null;

  const stats: StatColumn<LeaderboardPlayer>[] = useMemo(
    () => statsColumns(t, includeSeasonPoints, includeWinRate, replaceSeasonPoints),
    [i18n.language, includeSeasonPoints, includeWinRate, replaceSeasonPoints]
  );

  const fuse = useMemo(
    () => createFuse(selected.lineup, (player) => player.name),
    [selected.lineup]
  );

  useEffect(() => {
    if (currentQuery.trim().length === 0) {
      setPlayersFiltered(selected.lineup);
    }
    else {
      setPlayersFiltered(
        fuse.search(currentQuery).map((result) => result.item.player)
      );
    }
  }, [selected, currentQuery]);

  return (
    <StyledChampionsLeaderboard>
      <ChampionsLeaderboardHeader
        leaderboards={leaderboards}
        selected={selected}
        onSelectLeaderboard={setSelected}
        onSearch={setCurrentQuery}
      />
      <ChampionsLeaderboardTable
        players={playersFiltered}
        stats={stats}
        highlightAndDividers={true}
      />
    </StyledChampionsLeaderboard>
  );
};

const statsColumns = (
  t: TFunction,
  includeSeasonPoints: boolean,
  includeWinRate: boolean,
  replaceSeasonPoints: boolean,
) => {
  const stats: StatColumn<LeaderboardPlayer>[] = [];

  // In cases where we don't show win rate, we instead should show LP.
  if (!includeWinRate) {
    stats.push({
      key: "lp",
      className: "lp",
      label: t("leaderboard:headers.lp"),
      getValue: (player) => player.lp,
      getRawValue: (player) => player.lp,
      getValueFormattingOptions: {},
      renderValue: (player) => t(
        "leaderboard:labels.lp",
        { value: player.lp.toLocaleString() }
      ),
      showDividers: true
    });
  }

  // Replace season points with league points
  if (replaceSeasonPoints) {
    stats.push({
      key: "lp",
      className: "lp",
      label: t("leaderboard:headers.lp"),
      getValue: (player) => player.lp,
      getRawValue: (player) => player.lp,
      getValueFormattingOptions: {},
      renderValue: (player) => t(
        "leaderboard:labels.lp",
        { value: player.lp.toLocaleString() }
      ),
      showDividers: true
    });
  }
  else if (includeSeasonPoints) {
    stats.push({
      key: "seasonPoints",
      className: "seasonPoints",
      label: t("leaderboard:headers.seasonPoints"),
      getValue: (player) => player.seasonPoints,
      getRawValue: (player) => player.seasonPoints,
      getValueFormattingOptions: {},
      renderValue: (player) => t(
        "leaderboard:labels.seasonPoints",
        { value: player.seasonPoints.toLocaleString() }
      )
    });
  }

  if (includeWinRate) {
    stats.push({
      key: "winRate",
      className: "winRate",
      label: t("leaderboard:headers.winRate"),
      getValue: (player) => player.winRate,
      getRawValue: (player) => player.winRate,
      getValueFormattingOptions: {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2},
      renderValue: (player) => t(
        "leaderboard:labels.winRate",
        { value: player.winRate.toLocaleString(undefined, {style: "percent", minimumFractionDigits: 2,
            maximumFractionDigits: 2}) }
      )
    });
  }

  return stats;
};

export default ChampionsLeaderboard;
